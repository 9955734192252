.chatBotDiv{
    width: 250px;
    height: 40vh;
    min-height: 300px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    position: fixed;
    bottom: 120px;
    right: 90px;
    border-radius: 12px;
    overflow: hidden;
}

.botImg{
    position: fixed;
    bottom: 100px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
}

.botBar{
    width: 100%;
    height: 15%;
    overflow: hidden;
    border-bottom: 1px solid rgb(61, 60, 60);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.botBar img{
    width: 40px;
}

.botBar h4{
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    margin-bottom: -2px;
    font-size: 21px;
}

.botBar i{
    position: relative;
    right: -30px;
}

.chatSection{
    width: 100%;
    height: 85%;
    overflow-y: scroll;
    overflow-x: hidden;
}

/* .inputBot{
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputBot div{
   border-radius: 5px;
   border: 1px solid black;
   padding: 2px 4px;
   margin: 2px 5px;
   
} */