.loginDiv{
    width: 100vw;
    height: auto;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginform{
    width: 100%;
    max-width: 600px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.loginform h3{
    color:#e8b10d;
}

.loginform form{
    width: 100%;
}

.login_form{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-evenly;
}

.login_form input{
    width: 90%;
    padding: 5px;
    border: none;
    outline: none;
    border-bottom: 1px solid #0251aa;
    border-radius: 5px;
    margin: 20px auto;
    max-width: 400px;
}

.login_form button{
    width: 150px;
    height: auto;
    padding: 20px 10px;
    background: #0251aa;
    margin-top: 50px;
    color: white;
    border: none;
    border-radius: 10px;
}

.login_form button:hover{
    background: #216abd;
}

.loginCross{
    position: absolute;
    font-size: 2.2rem;
    top: 10px;
    right: 20px;
    color: #e8b10d;
}

.dangertxt{
    color: rgb(223, 58, 58) !important;
}