.testismonialMainDiv {
  width: 100vw;
  height: auto;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgba(188, 208, 224, 0.342);
  font-family: 'Montserrat', sans-serif;
}

.testismonialMainDiv h1 {
  font-size: 51px;
  padding: 20px 5px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-align: center;
}

.testimonialDiv {
  width: 100%;
  max-width: 800px;
  height: 100%;
  display: block;
  margin: 20px auto;
  /* margin-bottom: 100px; */
  padding: 30px 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: white;
  cursor: pointer;
}

.client-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 20px;
}

.client-image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.client-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.client-designation {
  font-size: 1rem;
  margin-bottom: 20px;
}

.client-comment {
  font-size: 1.1rem;
  line-height: 1.5;
  margin: 0px 15px;
  text-align: left;
}


/* Our partners 😎 */

.sliderDivMainMost {
  width: 95vw;
  height: auto;
  min-height: 20vh;
  max-width: 1200px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  position: relative;
  bottom: -70px;
}

.sliderDivMainMost h3 {
  font-size: 39px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

.partnerDIvMain {
  width: 100%;
  display: block;
  margin: 10px 5px;
  cursor: pointer;
}

.partnersDiv img {
  width: 200px;
  height: auto;
  margin: 5px auto;
  cursor: pointer;
}

.dummyGap {
  width: 100vw;
  height: 10vh;
  min-height: 100px;
}

/* Dotte dlines */

.dotted {
  width: 90%;
  border: 1px dashed black;
  background-image: linear-gradient(to right, black 33%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;
}

@media screen and (max-width: 950px) {
  .testismonialMainDiv h1 {
    font-size: 48px;
  }
}

@media screen and (max-width: 1100px) {
  .partnersDiv img {
    width: 20vw;
  }

  .sliderDivMainMost h3 {
    font-size: 40px;
  }

}

@media screen and (max-width: 900px) {
  .testimonialDiv {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .testismonialMainDiv h1 {
    font-size: 45px;
  }

  .sliderDivMainMost h3 {
    font-size: 30px;
  }

}

.sliderDivMainMostInc {
  margin: 50px auto;
}