.searchBarMain{
    width: 40vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    top: 5px;
    left: 1vw;
}

.searchbarDiv {
    width: 40vw;
    height: auto;
    padding: 2px 5px;
    background-color: white;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.searchbarDiv input {
    width: 38vw;
    border-radius: 10px;
    padding: 5px 10px;
    outline: none;
    border: none;
}

.searchbarDiv i{
    cursor: pointer;
    margin-right: 5px;
}

.searchbarResults{
    width: 40vw;
    height: auto;
    max-height: 30vh;
    overflow-y: scroll;
    background-color: white;
    position: relative;
    z-index: 99;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    top: 35px;
}

.searchbarResultsSub{
    padding: 5px;
    color: black;
    width: 100%;
}

.searchbarResultsSub:hover{
    color: black;
    text-decoration: none;
    background-color: rgba(229, 224, 224, 0.286);
}

.nomoreResults{
    color: grey;
}

.nomoreResults:hover{
    color: grey;
    background-color: white;
}

@media screen and (max-width:600px) {
    .searchbarDiv{
        display: none;
    }
    .searchBarMain{
        display: none;
    }
}
