body{
  width: 100vw;
  height: auto;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
