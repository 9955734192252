.coinAnimeMain {
    width: 100vw;
    height: auto;
    min-height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin-top: 50px;
    /* overflow-x: hidden; */
}

.coinBag {
    width: 200px;
    height: auto;
}

.coinImg {
    max-width: 5% !important;
    height: auto;
    position: relative;
}

.coinDiv {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.coinDivSub {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin: 1vw;
}

@media screen and (max-width:500px) {
    .coinAnime{
        display: none;
    }
    .partOneDivFive{
        display: none;
    }
}