/* Position and sizing of burger button */
.bm-burger-button {
    position: relative;
    width: 36px;
    height: 30px;
    top: -10px;
    left: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #ffffff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #e3e3e3;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    top: 0;
}

/* General sidebar styles */
.bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
}

/* Individual item */
.bm-item {
    display: inline-block;
    color: #d1d1d1;
    margin-bottom: 10px;
    text-align: left;
    text-decoration: none;
    transition: color 0.2s;
}

.bm-item:hover {
    color: #ffffff;
    text-decoration: none !important;
}

.menuItemSub{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    display: none;
    transition: 2s;
    animation: fadeIn 5s;
}

.menuLogoText{
    position: relative;
    top: -2vh;
}

.menu-item:hover > .menuItemSub {
    display: flex;
}

@keyframes fadeIn {
    0% {
        display: none;
    }

    100% {
        display: flex;
    }
}