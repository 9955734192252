.privacyUpper{
    width: 100vw;
    height: auto;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.privacyMain{
    width: 100vw;
    max-width: 1200px;
}

.privacySubDiv{
    width: 100%;
    height: auto;
    padding: 10px;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    margin: 20px 10px;
}

.privacySubDiv h3{
    font-size: 2rem;
    font-weight: bolder;
    margin-bottom: 20px;
}

.privacySubDiv p{
    font-size: 16px;
    font-weight: 600;
    /* margin-left: -10px; */
    margin-left: 20px;
    word-spacing: 2px;
}