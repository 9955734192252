.servNavMain {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 999;
    transition: 0.45s;
}

.servNav {
    width: 100%;
    max-width: 1200px;
    height: auto;
    min-height: 100px;
    background-color: transparent !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.navbar {
    /* transition: 5s; */
    width: 100% !important;
}

/* .dropdown-menu{
    padding-left: 0px !important;
} */

.lolxd {
    background: transparent !important;
}

.lolxdwhite {
    background: white !important;
}

.text-whitexd {
    color: white !important;
}

.text-blackxd {
    color: black !important;
}

.one-edge-shadow {
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -6px black;
}

.dropdown-menu{
    transition: 1s !important;
}

.nav-item{
    transition: 1s !important;
}

/* @media screen and (max-width: 911px) {
   

} */