@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;600;&display=swap');

/* Blog */
#blogPart {
    width: 100vw;
    height: auto;
    min-height: 500px;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.blogDivSep {
    width: 100%;
    height: auto;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
}

.blogSubDivSep {
    width: 300px;
    height: 270px;
    /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin: 10px !important;
}

.blogSubDivSep:hover {
    transform: scale(1.05);
}

.blogSubDivSep img {
    width: 100%;
    height: 200px;
    border-radius: 10px 10px 0px 0px;
}

.blogSubDivAfterImg {
    width: 100%;
    height: 50%;
    padding: 10px;
}

.blogSubDivAfterImg button {
    background-color: rgb(243, 226, 36);
    color: #ffffff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}

/* Blogs */

.blogtextMain {
    width: 100vw;
    height: auto;
    display: grid;
    place-content: center;
    margin-top: 50px;
    font-family: 'Montserrat', sans-serif;
}

.blogtext {
    width: 100%;
    max-width: 900px;
    height: auto;
    padding: 10px;
}

/* Blog1 */
.blogBannerBlog1 {
    width: 100vw;
    height: 70vh;
    background-image: url("../Blogs/images/Blog1/Blog1Banner.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and (max-width:1050px) {
    .blogBannerBlog1 {
        background-image: url("../Blogs/images/Blog1/Blog1Banner600.png");
    }
}

@media screen and (max-width:600px) {
    .blogBannerBlog1 {
        background-image: url("../Blogs/images/Blog1/Blog1Banner300.png");
    }
}

/* Blog2 */
.blogBannerBlog2 {
    width: 100vw;
    height: 70vh;
    background-image: url("../Blogs/images/Blog2/Blog2Banner.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and (max-width:1050px) {
    .blogBannerBlog2 {
        background-image: url("../Blogs/images/Blog2/Blog2Banner600.png");
    }
}

@media screen and (max-width:600px) {
    .blogBannerBlog2 {
        background-image: url("../Blogs/images/Blog2/Blog2Banner300.png");
    }
}

/* Blog3 */
.blogBannerBlog3 {
    width: 100vw;
    height: 70vh;
    background-image: url("../Blogs/images/Blog3/Blog3Banner.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and (max-width:1050px) {
    .blogBannerBlog3 {
        background-image: url("../Blogs/images/Blog3/Blog3Banner600.png");
    }
}

@media screen and (max-width:600px) {
    .blogBannerBlog3 {
        background-image: url("../Blogs/images/Blog2/Blog2Banner300.png");
    }
}

/* Blog4 */
.blogBannerBlog4 {
    width: 100vw;
    height: 70vh;
    background-image: url("https://i.ibb.co/VMbVX2j/1.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and (max-width:1050px) {
    .blogBannerBlog4 {
        /* background-image: url("../Blogs/images/Blog3/Blog3Banner600.png"); */
    }
}

@media screen and (max-width:600px) {
    .blogBannerBlog4 {
        background-image: url("https://i.ibb.co/cb9zhSN/Blog3-Banner300.png");
    }
}

/* Blog5 */
.blogBannerBlog5 {
    width: 100vw;
    height: 70vh;
    background-image: url("https://i.ibb.co/c2x0YG6/2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and (max-width:1050px) {
    .blogBannerBlog5 {
        /* background-image: url("../Blogs/images/Blog3/Blog3Banner600.png"); */
    }
}

@media screen and (max-width:600px) {
    .blogBannerBlog5 {
        background-image: url("https://i.ibb.co/p0Mbsj1/Blog3-Banner300-2.png");
    }
}

/* Blog6 */
.blogBannerBlog6 {
    width: 100vw;
    height: 70vh;
    background-image: url("https://i.ibb.co/w7b92Nc/3.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and (max-width:1050px) {
    .blogBannerBlog6 {
        /* background-image: url("../Blogs/images/Blog3/Blog3Banner600.png"); */
    }
}

@media screen and (max-width:600px) {
    .blogBannerBlog6 {
        background-image: url("https://i.ibb.co/sWF1NHv/Blog3-Banner300-3.png");
    }
}

/* Blog7 */
.blogBannerBlog7 {
    width: 100vw;
    height: 70vh;
    background-image: url("../Blogs/images/Blog7/blog7main.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and (max-width:1050px) {
    .blogBannerBlog7 {
        /* background-image: url("../Blogs/images/Blog3/Blog3Banner600.png"); */
    }
}

@media screen and (max-width:600px) {
    .blogBannerBlog7 {
        background-image: url("../Blogs/images/Blog7/blog7mobile.png");
    }
}

/* Blog8 */
.blogBannerBlog8 {
    width: 100vw;
    height: 70vh;
    background-image: url("../Blogs/images/Blog8/blog8main.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and (max-width:1050px) {
    .blogBannerBlog8 {
        /* background-image: url("../Blogs/images/Blog3/Blog3Banner600.png"); */
    }
}

@media screen and (max-width:600px) {
    .blogBannerBlog8 {
        background-image: url("../Blogs/images/Blog8/blog8mobile.png");
    }
}

/* Blog9 */
.blogBannerBlog9 {
    width: 100vw;
    height: 70vh;
    background-image: url("../Blogs/images/Blog9/blog9main.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and (max-width:1050px) {
    .blogBannerBlog9 {
        /* background-image: url("../Blogs/images/Blog3/Blog3Banner600.png"); */
    }
}

@media screen and (max-width:600px) {
    .blogBannerBlog9 {
        background-image: url("../Blogs/images/Blog9/blog9mobile.png");
    }
}