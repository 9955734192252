.notificationsMain {
    width: 100vw;
    height: auto;
    min-height: 100vh;
    background-color: rgb(9, 9, 9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.notifications {
    width: 90%;
    max-width: 400px;
    height: 90vh;
    overflow-y: auto;
    background-color: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.notificationsSubMain {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    border-bottom: 1px solid rgb(154, 154, 154);
    cursor: pointer;
    margin-bottom: 10px;
}

.notificationsSubMainh5 {
    /* color: #0251aa; */
    margin-top: 10px;
}

.notificationsSubMaini {
    color: #ffc107 !important;
}

.noNewNotiDiv {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.noNewNotiDiv i {
    font-size: 2.5rem;
    margin-top: 10px;
}

.notiMainDiv {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 5px 10px;
}

.notiMainDiv img {
    width: 10%;
    height: auto;
    border-radius: 50%;
}

.notiMainDiv div {
    width: 90%;
    padding-left: 10px;
}

.infoBtn {
    background: none;
    border: none;
    float: right;
    position: relative;
    top: -5px;
}

.dateNoti {
    background-color: rgb(54, 54, 54);
    color: white;
    padding: 2px 5px;
    border-radius: 5px;
    position: relative;
    left: -5px;
    z-index: 5;
    display: none;
}

.infoBtn:hover>.dateNoti {
    display: inline-block;
}

.infoBtn:active>.dateNoti {
    display: inline-block;
}

.infoBtn:focus>.dateNoti {
    display: inline-block;
}

/*Settings page */

.settings_container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid rgb(197, 193, 193);
    padding-top: 50px;
}

.changePass_div {
    width: 95%;
    height: auto;
    padding: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.changePass_div form {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.changePass_div form input {
    width: 98%;
    max-width: 300px;
    height: auto;
    padding: 10px;
    margin-bottom: 10px;
}

.changePass_div form button {
    width: 200px;
    height: auto;
    padding: 15px;
    background-color: #ffc107;
    color: white;
    border: none;
    border-radius: 2px;
    margin-top: 50px;
}

.changePass_div form input::placeholder {
    color: black;
    opacity: 1;
    /* Firefox */
}

.changePass_div form input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: black;
}

.changePass_div form input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: black;
}

.deleteAcc_div {
    width: 95%;
    height: auto;
    padding: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}

.deleteAcc_div form {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.deleteAcc_div form input {
    width: 98%;
    max-width: 300px;
    height: auto;
    padding: 10px;
    margin-bottom: 10px;
}

.deleteAcc_div form button {
    width: 200px;
    height: auto;
    padding: 15px;
    background-color: #ffc107;
    color: white;
    border: none;
    border-radius: 2px;
    margin-top: 50px;
}

.deleteAcc_div form input::placeholder {
    color: black;
    opacity: 1;
    /* Firefox */
}

.deleteAcc_div form input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: black;
}

.deleteAcc_div form input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: black;
}

/* Radio buttons */
input[type=radio]#Red {
    accent-color: #ec1c23;
}


.settingHuman {
    color: black !important;
    margin-left: 20px;
    font-size: 1.5rem;
    margin-top: 5px;
}

/* .profileDiv{
    width: 100%;
    height: auto;
    min-height: 500px;
    overflow-x: auto;
} */