@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Raleway:wght@200&display=swap');

body {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
}

#partOne {
    width: 100vw;
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    /* background-color: #3744bd;  */
    /* background-color: #1a235c; */
    /* background-color: #1a235c; */
}

.partOneDivOne {
    width: 100vw;
    height: auto;
    min-height: 32px;
    background-color: #0251aa;
    color: rgb(196, 218, 32);
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 5px 10px;
}

.notifyHeading {
    font-size: 12px !important;
    margin-top: 10px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    text-align: center;
}


@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.animate-flicker {
    -webkit-animation: flickerAnimation 2s infinite;
    -moz-animation: flickerAnimation 2s infinite;
    -o-animation: flickerAnimation 2s infinite;
    animation: flickerAnimation 2s infinite;
}

.navBarHome {
    width: 100vw;
    height: 90px;
    background-color: #000e17;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* position: fixed; */
}

.logoSearch{
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.homeLogo {
    width: 200px;
    height: auto;
    /* position: relative;
    left: -2vw; */
}

.loginBtn {
    margin-right: 70px;
    background: #0251aa;
    padding: 10px;
    width: 100px;
    height: 40px;
    border-radius: 12px;
    line-height: 10px;
    color: white;
    border: 1.5px solid white;
}

@media screen and (max-width: 1250px) {
    .loginBtn {
        margin-right: 50px;
    }
}

@media screen and (max-width: 1150px) {
    .loginBtn {
        margin-right: 20px;
    }
}

@media screen and (max-width: 1050px) {
    .loginBtn {
        margin-right: 5px;
    }
}

@media screen and (max-width: 850px) {
    .loginBtn {
        margin-right: 10px;
        width: 70px;
    }
}

.partOneThree {
    width: 100vw;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.toggleDiv {
    width: 100vw;
    max-width: 1080px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.toggleDiv div p {
    font-size: 1.1rem;
    font-weight: 500;
    margin: 2px 10px;
    color: #ffffff !important;
    /* border: 1px solid black; */
    /* text-shadow: 1px 1px #000000; */
    /* text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black; */
    /* border-right: 2px solid black; */
    /* border-left: 2px solid black; */
    /* border-bottom: 2px solid black; */
    padding: 5px 12px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    background-color: #0251aa;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    position: relative;
    top: 2px;
    /* padding-bottom: 10px; */
}


/* Dropdown css */
.dropdown:hover .dropdown-menu {
    display: block !important;
    /* remove the gap so it doesn't close */
    margin-left: 5px;
}

.dropdown-item {
    color: rgb(0, 0, 0) !important;
}

/* From slider */

.partOneDivFour {
    width: 100vw;
    max-width: 1700px;
    height: auto;
    min-height: 65vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 50px;
}

.sliderDiv {
    width: 65%;
    height: auto;
    min-height: 70vh;
    border-radius: 10px;
}

.sliderDivBeside {
    width: 30%;
    height: auto;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.besideDivFirst {
    width: 90%;
    height: 33vh;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 15px;
    /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 10px;
    margin-bottom: 2vh;
    margin-left: 10px;
    overflow: hidden;
    background: #000e17;
    /* background: url("../BFS\ LOGO\ -\ About\ us.png") no-repeat center center; */
}

.besideDivFirst img {
    width: 50%;
}

.besideDivSecond {
    width: 90%;
    height: 33vh;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 15px;
    /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
    margin-left: 10px;
}

.quickLinks {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    text-align: left;
}

.quickLinksSub {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
}

.quickLinksSub div {
    width: 50%;
    height: 16vh;
    display: flex;
    flex-direction: row-reverse !important;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
}

.quickLinksSubNavlink {
    width: 100%;
    height: 16vh;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1.2rem;
    border-radius: 12px;
    text-decoration: none;
    color: black;
    text-align: center;
}

.quickLinksSubNavlink i {
    font-size: 2rem;
}

.quickLinksSubNavlink:hover {
    text-decoration: none;
}

.quickLinksSub div {
    background: inherit;
    transition: 1s;
}

/* .quickLinksSubNavlink{
    position: relative;
    z-index: 1;
}
.quickLinksSubNavlink::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scaleY(0);
    transform-origin: bottom center;
    background: rgba(85, 85, 197, 0.445);
    z-index: -1;
    transition: transform 0.3s;
}
.quickLinksSubNavlink:hover::after {
    transform: scaleY(1);
} */

.quickLinksSub div:hover {
    transform: scale(1.1);
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.carouselSide {
    width: 100% !important;
    height: 70vh !important;
    min-height: 60vh !important;
    /* background-color: #3744bd; */
    border-radius: 10px;
    color: white !important;
}

.carouselSide1 {
    background: url("../HomeBan1.png") no-repeat center center;
    background-size: cover;
    background-blend-mode: multiply;
}

.carouselSide2 {
    background: url("../HomeBan2.png") no-repeat center center;
    background-size: cover;
    background-blend-mode: multiply;
}

.carouselSide3 {
    background: url("../HomeBan3.png") no-repeat center center;
    background-size: cover;
    background-blend-mode: multiply;
}

.carouselSlideOne {
    height: auto;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    padding: 5%;
}

.carouselSlideOne h4 {
    margin: 10px;
    font-size: 1.3em;
    font-weight: bolder;
    font-family: 'Red Hat Display', sans-serif;
}

.carouselSlideOne h2 {
    font-weight: bolder;
    margin: 10px;
}

.carouselSlideOne p {
    margin: 10px;
    width: 400px;
    box-sizing: border-box;
}

.btnDivHome {
    width: 70%;
    height: auto;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.btnDivHome button {
    width: 200px;
    height: auto;
    padding: 20px;
    border-radius: 20px;
    background-color: rgb(37, 92, 163);
    color: white;
    border: none;
    outline: none;
    font-weight: 1000;
}

/* .carouselSlideTwo{
    width: 20%;
    height: 20%;
    position: absolute;
    top: 10%;
    right: -3%;
} */

/* .carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  outline: black;
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid black;
  background-image: none;
} */

.carousel-control-next-icon:after {
    content: ' ';
    font-size: 50px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-family: 'Red Hat Display', sans-serif;
}

/* .carousel-control-prev:hover
{
    content: '';
    font-size: 50px;
    color: rgb(255, 255, 255);
    background: rgba(43, 42, 42, 0.618);
}

.carousel-control-next:hover {
  content: '';
  font-size: 50px;
  color: rgb(255, 255, 255);
  background: rgba(43, 42, 42, 0.618);
} */

.carousel-control-prev-icon:after {
    content: ' ';
    font-size: 50px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-family: 'Red Hat Display', sans-serif;
}

.partOneDivFive {
    width: 100vw;
    height: auto;
    background-color: #ffffff;
    color: white;
    padding: 5px;
}

.essenceOfBhandare {
    width: 40%;
    padding: 10px;
    color: white;
    background-color: #0251aa;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.ourSetOfServices {
    width: 40%;
    padding: 10px;
    color: white;
    background-color: #0251aa;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder !important;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.whiteboxDiv {
    width: 100vw;
    height: auto;
    min-height: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 40px;
}

.coinAnime {
    width: 100vw;
    height: auto;
    min-height: 200vh;
    scroll-behavior: smooth;
}

/* .whiteboxDiv div{
    width: 70px;
    height: 70px;
    background-color: white;
    margin: 10px;
    color: black;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
} */

.whiteboxDivDiv {
    width: 70px;
    height: 70px;
    background-color: white;
    margin: 10px;
    color: black;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
}


.fade-in-image {
    animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.iconDiv {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.iconDiv i {
    color: black;
    background: white;
    padding: 5px;
    font-size: 20px;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
}

/* Part Two */

.partTwo {
    width: 100vw;
    height: auto;
    min-height: 110vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.partTwoDivOne {
    width: 80vw;
    height: auto;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    /* border: 2px solid black; */
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    border-radius: 5px;
    margin-bottom: -20px;
}

.partTwoDivOne h3 {
    font-size: 55px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    margin-top: 40px;
    text-align: center;
}

.linesDiv h6 {
    text-align: left;
    font-size: 1.5rem;
}

.featuresDiv {
    width: 100vw;
    max-width: 1440px;
    height: auto;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.featuresDivDiv {
    width: 95vw;
    height: auto;
    min-height: 50vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    transition: 1s;
}

.featuresDivDivdiv {
    width: 220px;
    height: auto;
    min-height: 55vh;
    /* border: 2px solid black; */
    /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: 1s;
}

.featuresDivDivdiv:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    justify-content: flex-start;
    transition: 1s ease-in-out;
}

.featuresDivDivdivSub {
    width: 100%;
    height: 22vh;
    background-color: white !important;
    padding-top: 20px;
    transition: 1s;
}

.featuresDivDivdivSub1 div {
    width: 100%;
    height: auto;
    min-height: 33vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    /* border: 1px solid black; */
    margin-top: 10px;
    display: none;
    /* padding-bottom: 10px; */
    transition: 1s;
}

.featuresDivDivdiv:hover>.featuresDivDivdivSub1 div {
    display: flex;
    transition: 1s;
}

.featuresDivDivdivSub1 div span {
    margin-top: 1vh;
    transition: 1s;
}

.navServices {
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 18px;
}

.servicesIcons{
    width: 40%;
    height: auto;
}

/* Services after click */

.featuresDivDivdivSubAfter {
    width: 100%;
    height: auto;
    min-height: 22vh;
    background-color: white !important;
    padding-top: 20px;
    transition: 1s;
}

.featuresDivDivdivSub1After {
    width: 100%;
    height: auto;
    min-height: 33vh;
    background-color: grey;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.featuresDivDivdivSub1After div {
    width: auto;
    height: auto;
    min-height: 33vh;
    display: flex;
    flex-direction: column;
    align-items: flex;
    justify-content: flex-start;
    text-align: left;
    margin-top: 10px;
    transition: 1s;
    border-radius: 12px;
}

.featuresDivDivdivSub1After div span {
    margin-top: 7px;
}

.navServicesAfter {
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 18px;
}

/* After ends */

/* HOver ANIme */

.featuresDivDivdiv {
    position: relative;
    z-index: 1;
}

.featuresDivDivdiv::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40vh;
    transform: scaleY(0);
    transform-origin: bottom center;
    background: rgba(0, 0, 0, 0.445);
    color: white !important;
    z-index: -1;
    transition: transform 0.5s ease-in-out;
    border-radius: 12px;
}

.featuresDivDivdiv:hover::after {
    transform: scaleY(1);
}

.featuresDivDiv div i {
    font-size: 2rem;
    margin-bottom: 10px;
}

/* Part 4 */

.partFour {
    width: 100vw;
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 20px;
}

.contactP {
    color: black !important;
}

.contactUsDiv {
    width: 100%;
    max-width: 1440px;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contactPartOneMain {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.contactPartOne {
    width: 40%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}

.contactPartOneSub {
    width: 90%;
    max-width: 500px;
    height: 100px;
    background-color: white;
    margin: 10px 2%;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: auto 10px;
}

.contactPartOneSub i {
    width: 20%;
    height: auto;
    font-size: 30px;
    text-align: center;
    color: #ffc107;
}

.contactPartOneSub div {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.contactPartOneSub div p {
    margin-top: -1px;
    margin-bottom: -2px;
}

.contactForm {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: rgb(0, 0, 0);
    background-color: #0251aa;
    padding: 20px;
    border-radius: 10px;
}

.contactForm h4 {
    font-size: 41px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    margin-bottom: 15px;
    margin-left: 20px;
    color: white;
}

.inputsDiv {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-evenly;
    font-family: 'Montserrat', sans-serif;
}

.inputsDivOne {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.inputsDivOneSub {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.inputsDivOneSubMsg {
    display: none;
}

.inputsDivOneSub p {
    margin-bottom: -1px;
    font-weight: 600;
    color: white !important;
}

.inputsDivOneSub div {
    width: 100%;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
}

.inputsDivOneSub div input {
    width: 90%;
    border: none;
    outline: none;
    margin-left: 12px;
    font-weight: 600;
}

.inputsDivOneSub div textarea {
    width: 90%;
    border: none;
    outline: none;
    margin-left: 12px;
    font-weight: 600;
    display: none;
}

.contactPartTwo {
    width: 90%;
    height: auto;
    min-height: 60vh;
    border-radius: 10px;
    margin: 5% auto;
}

.inputsDivTwo textarea {
    border: none;
    outline: none;
    border-radius: 10px;
    font-weight: 600;
    margin-left: 10px;
    padding: 10px;
}

.inputsDivTwo p {
    margin-bottom: -1px;
    font-weight: 600;
    color: white !important;
    margin-left: 10px;
}

.submitContact {
    width: 200px;
    height: auto;
    padding: 10px;
    color: rgb(0, 0, 0);
    background-color: #ffc107;
    border-radius: 10px;
    outline: none;
    font-weight: bolder;
    font-size: 21px;
    border: none;
}

/* whatsapp */
.whatsappBtn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 9999;
}

.upButton{
    position: fixed;
    bottom: 100px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 9999;
    background: white;
    border: 3px solid black;
}

@media screen and (max-width: 700px) {
    .whatsappBtn {
        display: none;
    }
    
    .upButton{
        display: none;
    }
}

.dflexlol {
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

#cursorDiv {
    cursor: url("./cashCursorr.png"), auto;
    /* cursor: url("./ezgif.com-resize.gif") , auto;  */
    /* use keyframes animation */
}

/* Responsive */

@media screen and (max-width: 1100px) {
    .ourSetOfServices{
        width: 70%;
    }
    .quickLinksSubNavlink i {
        font-size: 1.5rem;
    }

    .quickLinksSubNavlink {
        font-size: 1rem;
    }

    .navServices {
        margin-left: 5px !important;
    }
}

@media screen and (max-width: 980px) {
    .iconDiv {
        display: none;
    }

    /* .homeLogo { */
        /* width: 30vw; */
        /* position: relative; */
        /* left: -5vw; */
    /* } */

    .featuresDivDivdiv {
        width: 23%;
        margin: 20px auto;
    }

    .essenceOfBhandare {
        width: 60%;
    }
}

@media screen and (max-width: 750px) {
    .iconDiv {
        display: none;
    }

    /* .homeLogo {
        width: 30vw;
    } */

}

@media screen and (max-width: 700px) {
    .ourSetOfServices{
        margin-top: 100px;
        margin-bottom: 50px;
    }
    .homeInput {
        width: 30vw !important;
    }

    .partOneDivFour {
        flex-direction: column;
    }

    .sliderDiv {
        width: 90%;
    }

    .sliderDivBeside {
        width: 95%;
        flex-direction: column;
        min-height: auto;
        margin-top: 50px;
        justify-content: space-evenly;
    }

    .besideDivFirst {
        display: none;
    }

    .besideDivSecond {
        width: 90%;
        height: 40vh;
        overflow-y: hidden;
        overflow-x: hidden;
        margin-left: 0px;
    }

    .featuresDivDiv {
        flex-direction: column;
    }

    .featuresDivDivdiv {
        width: 60%;
    }

    .featuresDivDivdivSub1After {
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 600px) {
    .homeInput {
        display: none;
    }

    /* .homeLogo {
        width: 50vw;
    } */

    .toggleDiv div p {
        font-size: 1rem;
    }

    .partTwoDivOne h3 {
        font-size: 30px;
    }

    .essenceOfBhandare {
        width: 80%;
    }
    .ourSetOfServices{
        width: 90vw;
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 550px) {
    .toggleDiv div p {
        font-size: 1rem;
        margin: 2px 5px;
        padding: 5px 10px;
        position: relative;
        top: 2px;
    }
}

@media screen and (max-width: 500px) {
    .toggleDiv div p {
        font-size: 1rem;
        margin: 2px;
        padding: 5px 10px;
        position: relative;
        top: -2px;
    }

    .featuresDivDivdiv {
        width: 80%;
    }

    .essenceOfBhandare {
        width: 95%;
    }

}

@media screen and (max-width: 410px) {
    .toggleDiv div p {
        font-size: 0.8rem;
        margin: 2px 1px;
        padding: 5px 10px;
        position: relative;
        top: -2px;
    }
}

@media screen and (max-width: 1150px) {
    .contactPartOneMain {
        flex-direction: column;
    }

    .contactPartOne {
        width: 95%;
        flex-direction: row;
        justify-content: space-between;
    }

    .contactPartOneSub {
        width: 30%;
        height: 20vh;
        justify-content: space-evenly;
        margin: 10px 10px;
    }

    .contactPartOneSub i {
        width: 20%;
        height: auto;
        font-size: auto;
    }

    .contactPartOneSub div {
        width: 80%;
        height: 100%;
    }

    .contactForm {
        width: 90%;
    }

    .contactForm h4 {
        font-size: 31px;
    }
}

@media screen and (max-width: 865px) {
    .contactPartOne {
        width: 95%;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .contactPartOneSub {
        border: 1px solid grey;
        border-radius: 10px;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {

    .contactForm {
        width: 100vw;
        padding: 5px auto;
        border-radius: 0px;
    }

    .inputsDiv {
        justify-content: space-between;
    }

    .inputsDivOneSub div {
        width: 100%;
        background-color: rgb(255, 255, 255);
        padding: 10px 7px;
        border-radius: 10px;
    }

    .inputsDivOneSub div input {
        width: 90%;
        border: none;
        outline: none;
        margin-left: 2px;
        font-weight: 600;
    }

}

@media screen and (max-width: 500px) {

    .inputsDivOneSub div input {
        width: 90%;
        border: none;
        outline: none;
        margin-left: 12px;
        font-weight: 600;
    }

    .inputsDivOneSub div textarea {
        width: 90%;
        border: none;
        outline: none;
        margin-left: 12px;
        font-weight: 600;
        display: block;
    }

    .inputsDivTwo {
        display: none;
    }

    .inputsDivOneSubMsg {
        display: block;
    }

    .inputsDivOne {
        width: 100%;
        justify-content: center;
    }
}

@media screen and (max-width: 500px) {
    .toggleDiv div p {
        font-size: 15px;
        position: relative;
        top: 2px;
        /* width: 23vw; */
    }

    .loginBtn {
        margin-right: 0px;
        position: relative;
        right: 10px;
    }

    .homeLogo {
        width: 40vw;
    }
}

.hamburgerAbs {
    position: absolute;
    color: white !important;
    font-size: 2rem;
    top: 61px;
    left: 45px;
}

/* MEdia frames for banners home */
@media screen and (max-width: 1106px) {
    .carouselSide1 {
        background: url("../HomeBanMob1.png") no-repeat center center;
    }
    
    .carouselSide2 {
        background: url("../HomeMob2.png") no-repeat center center;
    }
    
    .carouselSide3 {
        background: url("../HomeMob3.png") no-repeat center center;
    }
}

@media screen and (max-width: 440px) {
    .partOneThree {
        display: none;
    }
}

.iconDivHamburger{
    display: none;
}

@media screen and (max-width: 981px){
    .iconDivHamburger{
        display: flex;
    }
}

/* Blog */
#blogPart{
  width: 100vw;
  height: auto;
  min-height: 500px;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.blogDiv{
    width: 100%;
    height: auto;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
}

.blogSubDiv{
    width: 300px;
    height: 270px;
    /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.blogSubDiv:hover{
    transform: scale(1.05);
}

.blogSubDiv img{
    width: 100%;
    height: 200px;
    border-radius: 10px 10px 0px 0px;
}

.blogSubDivAfterImg{
    width: 100%;
    height: 50%;
    padding: 10px;
}

.blogSubDivAfterImg button{
    background-color: rgb(243, 226, 36);
    color: #ffffff;
    border: none;
    padding: 12px;
    border-radius: 5px;
    margin-bottom: 10px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

.blogSubDivAfterImg button:hover{
    color: rgb(243, 226, 36);
    background-color: #ffffff;
    border: 2px solid rgb(243, 226, 36);
}

.readAllBlogs{
    width: 200px;
    height: auto;
    padding: 10px;
    border: none;
    border-radius: 10px;
    color: white;
    background-color: #0251aa;
    margin-top: 70px;
    font-family: "Montserrat" , sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
    text-align: center;
    text-decoration: none;
}

.readAllBlogs:hover{
    background-color: #237fe8;
    color: white;
    text-decoration: none;
}

.blog3{
    width: 300px;
    height: 270px;
    background: url("../blurBlog.png");
    background-position: center;
    border-radius: 10px;
}

@media screen and (max-width: 1029px){
    .blogDiv{
        flex-direction: column;
    }
    .blogSubDiv{
        margin: 20px auto;
    }
}
